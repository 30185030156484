import { FooterStyled } from "./style";
import Logo from "../../asserts/images/LOGO.jpeg.jpg";

export function Footer() {
  return (
    <FooterStyled>
      <div className="footer-div-1">
        <h4>Distribuidor Autorizado</h4>
        <img src={Logo} alt="" />
      </div>
      <div className="footer-div-2">
        <p>
          Nutrial Aracati Telefone: (88) 99696-3000 <br /> Rua coronel
          Alexandrino, 1363 Centro - Aracati-CE
        </p>
        <hr />
        <p>
          Nutrial Limoeiro do Norte Telefone: (88) 99905-0051 <br /> Rod. Presidente
          Geisel, 565 Bom Jesus - Limoeiro do Norte
        </p>
      </div>
    </FooterStyled>
  );
}
